<template>
  <div class="login_bg">
    <ul class="login">
      <li>
        <el-input v-model="mailAccount" class="login_input ico_1" type="mail" placeholder="请输入您的企业邮箱"></el-input>
      </li>
      <li>
        <VcodeButton :toSend="mailAccount"></VcodeButton>
      </li>
      <li>
      <el-input v-model="vcode" class="login_input ico_2" type="text" placeholder="请输入验证码"></el-input>
      </li>
      <li>
        <PrivaryExplain></PrivaryExplain>
      </li>
      <li>
        <RegisterButton :toSend="mailAccount" :vcode="vcode" :openId="openId" :to="to"></RegisterButton>
        <div class="tip">
				<p>
					* 西门子(深圳)磁共振有限公司需要您授权同意我们获取您的微信公开信息
				</p>
				<p>
					* 出于数据安全考虑，西门子医疗员工每90天需要重新绑定，谢谢理解
				</p>
			</div>
      </li>
    </ul>
    <div class="clause_bg">
	  </div>
  </div>
  
</template>

<script>
  import VcodeButton from '@/components/auth/VcodeButton.vue'
  import RegisterButton from '@/components/auth/RegisterButton.vue';
  import PrivaryExplain from '@/components/auth/PrivaryExplain.vue';
  import {mixin} from '@/mixin.js';
  export default {
    components:{
      VcodeButton,
      RegisterButton,
      PrivaryExplain
    },
    mixins:[mixin],
    data() {
      return {
        mailAccount: '',
        vcode: '',
        to:'',
        openId:'',
      }
    },
    created(){
      this.$store.state.imgSrc= require('@/assets/banner.jpg');
      this.$store.state.appBg= "login_bg";
      this.$store.state.headerBanner= "login_banner";
      this.$store.state.ifNeedHeader= false;
      if(!!this.$route.params){
        this.to=this.$route.params.to
        this.openId=this.$route.params.openId
      }
      //todo 放开注释
      // if(!this.openId){
      //   window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe33298f1908262c0&"+
      //   "redirect_uri=https%3A%2F%2Fcommunication-in-shenzhen.siemens-healthineers.cn%2Fauth%3Fnum%3D2"+
      //             +"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect");
      // }
    },
    methods:{
      
    }
  }
</script>

<style lang="css" scoped>
.login_bg{
	background: #de6a0a;
}
.login{
	padding:0 10vw;
}
.login li{
	padding-top: 4vw;
}

.clause_bg{
	display: none;
	position: fixed;
	left:0;
	top:0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 2;
}
.login_input /deep/ .el-input__inner{
	width: 100%;
	box-sizing: border-box;
	border:1px solid #333;
	background: #fff;
	height: 10vw;
	padding-left:13vw;
	font-size: 3.6vw;
	border-radius: 5vw;
	outline: none;
  vertical-align:middle;
}
.ico_1 /deep/ .el-input__inner{
	background:#fff url('@/assets/ico_1.png') no-repeat 5vw center;
	background-size: 4.5vw auto;
}
.ico_2 /deep/ .el-input__inner{
	background:#fff url('@/assets/ico_2.png') no-repeat 5vw center;
	background-size: 4.5vw auto;
}
.tip{
	padding: 2vw 0;
}
.tip p{
	padding:1vw 3vw 0;
	font-size: 2.2vw;
	line-height: 3.5vw;
	color:#000;
	font-weight: normal;
}
/deep/ .el-input__inner::placeholder {
  color: grey;
}




</style>