<template>
    <div class="sports">
        <div class="sports_side">
            <div v-for="(item,index) in sportsSideList" :key="index" class="sports_side_item"
             :class="item.class" @click="sportSideClick(item)">
                <div class="name">{{ item.name }}</div>
            </div>
        </div>

        <div class="sports_cont" v-loading="loading" :key="new Date().getTime()">
            <div class="ssmr_map">
                <h3 class="ssmr_map_tit">
                    <!-- <img :src="mapContent.titleImg" alt="" />
                    {{ mapContent.titleText }} -->
                </h3>
                <div class="ssmr_map_text" v-if="!!mapContent.contentTextList && mapContent.contentTextList.length>0">
                    <span v-for="(item,index) in mapContent.contentTextList" :key="index">
                        <span class="org">· </span><span v-html="item"></span>
                    </span>
                </div>
                <!--  -->
                <div class="ssmr_map_ph" v-if="!!mapContent.contentPh && mapContent.contentPh.length>0">
                    <span v-for="(item,index) in mapContent.contentPh" :key="index">
                        <img :src="item.img" width="100%" alt="">
                        <p v-if="!!item.p">{{ item.p }}</p>
                    </span>
                </div>

                <div class="ssmr_map_swiper swiper swiper-wrapper" width="100%" v-if="!!mapContent.contentSwiper && mapContent.contentSwiper.length>0">
                    <swiper :options="swiperOption" ref="mapSwipper">
                        <swiper-slide v-for="(item, index) in mapContent.contentSwiper" :key="index" class="swiper-last">
                            <div class="ssmr_map_text_swiper" v-html="item.text">
                            </div>
                            <img :src="item.img" width="100%" alt="">
                            <p>{{ item.p }}</p>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
	data(){
        return {
            loading:false,
            // sportsSideList:[
            //     {id:"mrArea",name:"MR产线区",class:"hover"},
            //     {id:"atArea",name:"AT产线区",class:""},
            //     {id:"meArea",name:"ME产线区",class:""},
            //     {id:"publicDep",name:"公共职能部门",class:""},
            //     {id:"mrDep",name:"MR部门",class:""},
            //     {id:"atDep",name:"AT部门",class:""},
            //     {id:"meDep",name:"ME部门",class:""},
            //     {id:"meetingDis",name:"会议室分布",class:""},
            // ],
            sportsSideList:[
                {id:"meetingArea",name:"会议室",class:"hover"},
                {id:"publicArea",name:"公共部门",class:""},
            ],
            mapContent:{
                titleImg:require('@/assets/ico_map.png'),
                titleText:'',
                contentTextList:[ //无值不展示 inhtml
                ],
                contentPh:[ //无值不展示,多值非轮播，排序
                ],
                contentSwiper:[//无值不展示
                ]
            },
            mapContentEmpty:{
                titleImg:require('@/assets/ico_map.png'),
                titleText:'',
                contentTextList:[],
                contentPh:[],
                contentSwiper:[]
            },
            swiperOption: {
                spaceBetween: 15, // 图片之间的间距
                centeredSlides: true, // 居中还是从图1开始
                slidesPerView: '1', // 一屏幕显示几个? 数字或者默认  auto 自动。
                notNextTick: false, // true:加载后允许触发事件 false:加载后不可以触发事件
                loop: true, // 循环吗 此处开启显示错误
                initialSlide: 0, // 从第几个开始
                autoplay: {
                    delay: 4000, // 等4秒下一个
                    disableOnInteraction: false // 中间滑动一下，取消自动吗？
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }, // 下按钮
                speed: 400, // 滑动时候动画的速度
                paginationClickable: true, // 下面按钮让点吗
                navigation: {
                    prevEl: '.swiper-button-prev', // 左侧按钮
                    nextEl: '.swiper-button-next' // 右侧按钮
                },
                effect: 'cards', // 渐入效果
                watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
                watchSlidesVisibility: true,  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
                autoHeight: true,  // 图片高度自适应
                observer:true,
                observeparents:true,
                // width:40,
            },
            
        }
    },
    created(){
		this.$store.state.imgSrc= require('@/assets/banner3.jpg');
        this.$store.state.appBg= "bgfff";
        this.$store.state.ifNeedHeader= true;
        this.$store.state.headerBanner= "club_banner";
        this.$store.state.headerContent= "map";
        this.getMap("meetingArea")
    },
    methods:{
        sportSideClick(row){
            this.sportsSideList.forEach(element => {
                element.class=""
            });
            row.class="hover"
            this.getMap(row.id)
        },
        getMap(param) {
            let _this=this;
            this.loading=true;
            this.$http(
                {
                    method: 'post',
                    url: `/api/getMap`,
                    data: {
                        "id": param,
                    }
                }
            ).then(
                (response)=>{
                    if(response.data.code==200){
                        //成功
                        let result=response.data.data;
                        result.titleImg=require("@/assets/"+result.titleImg)
                        if(!!result.contentPh && result.contentPh.length>0){
                            result.contentPh.forEach(item=>{item.img=require("@/assets/"+item.img)});
                        }
                        if(!!result.contentSwiper && result.contentSwiper.length>0){
                            result.contentSwiper.forEach(item=>{item.img=require("@/assets/"+item.img)});
                        }
                        Object.assign(_this.mapContent,_this.mapContentEmpty)
                        _this.$forceUpdate() 
                        Object.assign(_this.mapContent,result)
                        _this.loading=false;
                    }else{
                        _this.$message.error('获取页面失败，请刷新页面');
                    }
                }
            ).catch(
                (error)=>{
                    _this.$message.error('获取页面异常，请刷新页面');
                }
            )
        }
        
    }
    
}
</script>
<style lang="css" scoped>
.sports{
	position: relative;
	background: #ededed;
}
.sports_side{
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	background: #ededed;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.sports_side_item{
	color:#808080;
	width: 100%;
	height: 15vw;
	display: flex;
	align-items:center;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	position: relative;
	text-align: center;
	
}
.sports_side_item .name{
	font-size: 4vw;
	line-height: 3.4vw;
	font-weight: bold;
	text-align: center;
}
.sports_side_item .text{
	font-size: 2.6vw;
	line-height: 2.6vw;
	margin-top: 1.3vw;
	font-weight: normal;
	text-align: center;
}
/*
.sports_side_item::after{
	content: '';
	position: absolute;
	left:1.6vw;
	top:4vw;
	bottom: 4vw;
	width: 2px;
	background: #bfbfbf;
	border-radius: 1px;
}
*/
.sports_side_item.hover{
	background: #eecbb5;
	color: #ec6602;
}


.ssmr_map{
    background: #fff;
	padding:0 6vw;
}
.ssmr_map_tit{
	display: flex;
	align-items: center;
	color: #ec6602;
	font-size: 5.1vw;
	font-weight: bold;
	padding:8vw 0 0 1vw;
}
.ssmr_map_tit img{
	width: 5vw;
	margin-right: 2vw;
}
.ssmr_map_text{
	padding-top: 4vw;
	padding-left:9vw;
	font-size: 3.1vw;
	line-height: 6vw;
	color:#808080;
}
.ssmr_map_text_swiper{
	padding-top: 4vw;
    transform:translateX(9vw);
	font-size: 3.1vw;
	line-height: 6vw;
	color:#808080;
}
.ssmr_map_ph{
	padding-bottom: 8vw;
}
.ssmr_map_ph img{
	display: block;
	margin-top: 8vw;
}
.ssmr_map_ph p{
	text-align: center;
	font-size: 3.5vw;
	color: #ec6602;
	font-weight: bold;
	line-height: 5vw;
	padding-top: 4vw;
}
.ssmr_map_swiper{
	padding-bottom: 5vw;
}
.ssmr_map_swiper img{
	display: block;
	margin-top: 8vw;
}
.ssmr_map_swiper p{
	text-align: center;
	font-size: 3.5vw;
	color: #ec6602;
	font-weight: bold;
	line-height: 5vw;
	padding-top: 4vw;
}
.swiper{
	--swiper-pagination-color: #009999;
	--swiper-pagination-bullet-size:2.5vw;
	--swiper-pagination-bullet-horizontal-gap:1.3vw;
}
.swiper-wrapper{
	padding-bottom: 9vw;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
	bottom: 0;
}
.swiper-wrapper {
  width: 100%;
}
.swiper-slide {
  width: 100% !important;
  background-color: #fff;
}
.swiper-last{
    padding-bottom: 2.5em !important;
}
.swiper-pagination{
    /* width: 80% !important; */
}
.org{
	color: #ec6602;
}
</style>