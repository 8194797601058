<template>
    <div>
        <img :src="imgUrl" width="100%" alt="">
    </div>
</template>
<script>
export default {
    data(){
        return {
            imgUrl:"",
        }
    },
    created(){
        this.$store.state.loading=true;
        this.$store.state.imgSrc= "";
        this.$store.state.ifNeedHeader= false;
        let _this=this;
        this.$http.get("/api/getPoster").then(
            (response)=>{
                if(response.data.code==200){//重新获取数据
                    _this.imgUrl=require("@/assets/"+response.data.img)
                }
                _this.$store.state.loading=false
            }
        ).catch(
            (error)=>{
                _this.$message.error(error+'获取当前数据失败，请重新进入页面');
                _this.$store.state.loading=false
            }
        )
    },
    methods:{
        
    }
    
}
</script>
<style lang="css" scoped>
    
</style>