<template>
    <div class="sports">
        <div class="sports_side">
            <div v-for="(item,index) in sportsSideList" :key="index" class="sports_side_item" 
                :class="item.class" @click="sportSideClick(item)">
                <div class="name">{{ item.name }}</div>
                <div class="text">{{item.name_en}}</div>
            </div>
        </div>

        <div class="sports_cont" v-loading="loading">
            <div class="sports_cont_list">
                <div class="sports_item" v-show="ifReadingCorner">
                    <div class="sports_item_links">
                        <a class="btn_org" href="https://forms.office.com/Pages/ResponsePage.aspx?id=3Rq_XSogjUuBW78PsCTgM2-SMlHqHLpOstEG3bNE6etUMFNSWVNIU1hQUElaVTZKOUpSR1U2NEgxSCQlQCN0PWcu" hover-class="none">
                            <img src="@/assets/a_links.jpg" width="100%" alt="">
                        </a>
                    </div>
                </div>

                <div class="sports_item" v-show="!!sportSideContent.activeTime">
                    <div class="sports_item_tit">
                        <img class="ico" src="@/assets/ico_12.png" />
                        <div class="item">
                            <div class="name">
                                活动时间
                            </div>
                            <div class="en">
                                Routine
                            </div>
                        </div>
                    </div>
                    <div class="sports_item_cont" v-html="sportSideContent.activeTime">
                    </div>
                </div>
                <div class="sports_item">
                    <div class="sports_item_tit">
                        <img class="ico" src="@/assets/ico_22.png" />
                        <div class="item">
                            <div class="name">
                                活动地点
                            </div>
                            <div class="en">
                                Location
                            </div>
                        </div>
                    </div>
                    <div class="sports_item_cont" v-html="sportSideContent.location">
                    </div>
                </div>
                <div class="sports_item">
                    <div class="sports_item_tit">
                        <img class="ico" src="@/assets/ico_32.png" />
                        <div class="item">
                            <div class="name">
                                负责人
                            </div>
                            <div class="en">
                                Coordinator
                            </div>
                        </div>
                    </div>
                    <div class="sports_item_cont" v-html="sportSideContent.coordinator">
                    </div>
                    <div class="sports_item_email">
                        <img src="@/assets/ico_5.png" />
                        邮箱Email
                    </div>
                    <div class="sports_item_text">
                        {{ sportSideContent.email }}
                    </div>
                    <div class="sports_item_email">
                        <img src="@/assets/ico_4.png" />
                        部门Department
                    </div>
                    <div class="sports_item_text">
                        {{ sportSideContent.depatment }}
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    
</template>
<script>
import { Loading } from 'element-ui';

export default {
	data(){
        return {
            loading:false,
            ifReadingCorner:false,//是否展示图书馆图片
            sportsSideList:[
                {id:"tableTennis",name:"乒乓球",name_en:"Table Tennis",class:"hover"},
                {id:"tennis",name:"网球",name_en:"Tennis",class:""},
                {id:"basketball",name:"篮球",name_en:"Basketball",class:""},
                {id:"swimming",name:"游泳",name_en:"Swimming",class:""},
                {id:"badminton",name:"羽毛球",name_en:"Badminton",class:""},
                {id:"football",name:"足球",name_en:"Football",class:""},
                {id:"yoga",name:"瑜伽",name_en:"Yoga",class:""},
                {id:"orientalDance",name:"东方舞",name_en:"Oriental Dance",class:""},
                {id:"aerobics",name:"健身操",name_en:"Aerobics",class:""},
                {id:"gym",name:"健身房",name_en:"Gym",class:""},
                {id:"germanClub",name:"德语俱乐部",name_en:"German Club",class:""},
                {id:"bandClub",name:"乐队",name_en:"Band Club",class:""},
                {id:"readingCorner",name:"图书角",name_en:"Reading Corner",class:""},
            ],
            sportSideContent:{
                id:"",
                activeTime:"",
                location:"",
                coordinator:"",
                email:"",
                depatment:""
            }
        }
    },
    created(){
		// this.$store.state.loading=true
        this.$store.state.imgSrc= require('@/assets/banner2.jpg');
        this.$store.state.appBg= "bgfff";
        this.$store.state.ifNeedHeader= true;
        this.$store.state.headerBanner= "club_banner";
        this.$store.state.headerContent= "club";
        this.getClub("tableTennis")
    },
    methods:{
        sportSideClick(row){
            this.sportsSideList.forEach(element => {
                element.class=""
            });
            this.ifReadingCorner=false;
            row.class="hover"
            if(row.name=="图书角"){
                this.ifReadingCorner=true;
            }
            this.getClub(row.id)
        },
        getClub(param) {
            let _this=this;
            this.loading=true;
            this.$http(
                {
                    method: 'post',
                    url: `/api/getClub`,
                    data: {
                        "id": param,
                    }
                }
            ).then(
                (response)=>{
                    if(response.data.code==200){
                        //成功
                        Object.assign(_this.sportSideContent,response.data.data)
                        _this.loading=false;
                    }else{
                        _this.$message.error('获取页面失败，请刷新页面');
                    }
                }
            ).catch(
                (error)=>{
                    _this.$message.error('获取页面异常，请刷新页面');
                }
            )
        }
    }
    
}
</script>
<style lang="css" scoped>
.sports{
	position: relative;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	background: #ededed;
}
.sports_side{
	flex-shrink: 0;
	width: 26vw;
	height: 100%;
	background: #ededed;
}
.sports_side_item{
	color:#808080;
	height: 15vw;
	padding-left:4vw;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	position: relative;
	
}
.sports_side_item .name{
	font-size: 3.5vw;
	line-height: 3.4vw;
	font-weight: bold;
}
.sports_side_item .text{
	font-size: 2.6vw;
	line-height: 2.6vw;
	margin-top: 1.3vw;
	font-weight: normal;
}
.sports_side_item::after{
	content: '';
	position: absolute;
	left:1.6vw;
	top:4vw;
	bottom: 4vw;
	width: 2px;
	background: #bfbfbf;
	border-radius: 1px;
}
.sports_side_item.hover{
	background: #fff;
	color: #ec6602;
}
.sports_side_item.hover::after{
	background: #ec6602;
}
.sports_cont{
	width: 74vw;
	box-sizing: border-box;
	background: #fff;
}
.sports_cont_list{
	padding:0 6vw;
}
.sports_item{
	padding:6vw 0;
	border-bottom: 1px solid #ddd;
}
.sports_item_tit{
	display: flex;
	align-items: center;
}
.sports_item_tit .ico{
	width: 5.6vw;
	flex-shrink: 0;
	margin-right: 2vw;
}
.sports_item_tit .item{
	width: 100%;
}
.sports_item_tit .item .name{
	font-size: 3.4vw;
	font-weight: bold;
	color: #ec6602;
	line-height: 3.4vw;
}
.sports_item_tit .item .en{
	font-size: 3.2vw;
	color: #ec6602;
	line-height: 3.2vw;
	margin-top: 0.5vw;
}
		
.sports_item_cont{
	font-size: 3vw;
	color: #808080;
	line-height: 5.2vw;
	padding-left: 7.5vw;
	margin-top: 4vw;
}
.sports_item_email{
	display: flex;
	align-items: center;
	font-size: 2.8vw;
	font-weight: bold;
	color: #808080;
	line-height: 4vw;
	margin-top: 5vw;
	padding-left: 7.5vw;
}
.sports_item_email img{
	display: block;
	flex-shrink: 0;
	width: 3vw;
	margin-right: 1vw;
}
.sports_item_text{
	font-size: 2.8vw;
	color: #808080;
	line-height: 3vw;
	margin-top: 1.5vw;
	padding-left: 7.5vw;
}
</style>