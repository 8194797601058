<template>
    <div>
        <div class="about_main">
            <div class="about_title">
                <div class="name">
                    公司简介
                </div>
                <div class="text">
                    About SSMR
                </div>
            </div>
            <div class="about_ssmr">
                {{ baseInfo.aboutSsmr}}
            </div>
            <div class="about_text" v-html="baseInfo.aboutText">
            </div>
            <div class="line"></div>
            
            <div class="about_title">
                <div class="name">
                    主营业务
                </div>
                <div class="text">
                    Main Business
                </div>
            </div>
            <div class="business swiper swiper-wrapper">
                <swiper :options="swiperOption" ref="mainBusinesses" v-if="mainBusinesses.length>0">
                    <swiper-slide v-for="(item, index) in mainBusinesses" :key="index">
                        <img :src="item.imgUrl" width="100%" alt="">
                        <div class="about_ssmr swiper-bg">
                            {{ item.name_cn }}<br>{{ item.name_en }}
                        </div>
                        <div class="about_text2 swiper-bg swiper-last">{{ item.content }}</div>
                    </swiper-slide>
                    <!-- 根据情况引入控件 -->
                    <!-- 底部按钮 -->
                    <div class="swiper-pagination" slot="pagination"></div>
                    <!-- 上一张按钮 -->
                    <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
                    <!-- 下一张按钮 -->
                    <!-- <div class="swiper-button-next" slot="button-next"></div>  -->
                </swiper>
            </div>
            <div class="line"></div>
            
            <div class="about_title">
                <div class="name">
                    客户体验中心
                </div>
                <div class="text">
                    Customer Experience Center
                </div>
            </div>
            
            <div class="about_ssmr">
                {{ baseInfo.customerAboutSsmr }}
            </div>
            <div class="center_banner swiper swiper-wrapper">
                <swiper :options="swiperOption" ref="centerBanner" v-if="centerBanner.length>0">
                    <swiper-slide v-for="(item, index) in centerBanner" :key="index" class="swiper-last">
                        <img :src="item.imgUrl" width="100%" alt="">
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <div class="about_text">
                {{ baseInfo.customerAboutText }}
            </div>
            
            <div class="line"></div>
            <div class="about_title">
                <div class="name">
                    企业荣誉
                </div>
                <div class="text">
                    Enterprise Honor
                </div>
            </div>
            
            <div class="honor">
                <img class="ph" src="@/assets/ph_e.jpg" />
                <div class="text" v-html="baseInfo.honorText">
                </div>
            </div>
        </div>
        
        <div class="contact">
            <div class="contact_title">
                <div class="name">
                    联系我们
                </div>
                <div class="text">
                    Contact SSMR
                </div>
            </div>
            
            <div class="contact_gs">
                西门子（深圳）磁共振有限公司
            </div>
        
            <div class="contact_info">
                <img class="ico" src="@/assets/ico_22.png" />
                <div class="text">
                    {{baseInfo.contactInfoAddress}}
                </div>
            </div>
            
            <div class="contact_info">
                <img class="ico" src="@/assets/ico_6.png" />
                <div class="text">
                    {{baseInfo.contactInfoMail}}
                </div>
            </div>
            
            <div class="contact_info">
                <img class="ico" src="@/assets/ico_32.png" />
                <div class="text">
                    {{ baseInfo.contactInfoPhone }}
                </div>
            </div>
            <a class="contact_btn" href="https://minisite.shl-info.cn/home/aboutus.html?sourceopenid=ooAf61ULTaEKtjDDYwNCZMZhn_zQ&fromsource=&from=singlemessage" hover-class="none">点击了解西门子医疗</a>
        </div>
    </div>
</template>
<script>
export default {
	data(){
        return {
            mainBusinesses:[],//swiper 01 系统轮播数据
            centerBanner:[],//swiper 02 图片轮播链接
            baseInfo:{},
			loading:false,
            swiperOption: {
                spaceBetween: 15, // 图片之间的间距
                centeredSlides: true, // 居中还是从图1开始
                slidesPerView: '1', // 一屏幕显示几个? 数字或者默认  auto 自动。
                notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
                loop: true, // 循环吗
                initialSlide: 0, // 从第几个开始
                autoplay: {
                    delay: 4000, // 等4秒下一个
                    disableOnInteraction: false // 中间滑动一下，取消自动吗？
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }, // 下按钮
                speed: 800, // 滑动时候动画的速度
                paginationClickable: true, // 下面按钮让点吗
                navigation: {
                    prevEl: '.swiper-button-prev', // 左侧按钮
                    nextEl: '.swiper-button-next' // 右侧按钮
                },
                effect: 'cards', // 渐入效果
                watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
                watchSlidesVisibility: true,  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
                autoHeight: true  // 图片高度自适应
            },
        }
    },
    async created(){
		this.$store.state.loading=true
        this.$store.state.imgSrc= require('@/assets/banner1.jpg');
        this.$store.state.appBg= "bgfff";
        this.$store.state.ifNeedHeader= true;
        this.$store.state.headerBanner= "club_banner";
        this.$store.state.headerContent= "about";
        await this.initData()
    },
    methods:{
        async initData(){
            let _this=this;
            await this.$http.get("/api/getAbout").then(
                (response)=>{
                    if(response.data.code==200){//获取成功
                        response.data.data.mainBusinesses.forEach(item=>{item.imgUrl=require("@/assets/"+item.imgUrl)});
                        response.data.data.centerBanner.forEach(item=>{item.imgUrl=require("@/assets/"+item.imgUrl)});
                        _this.mainBusinesses=response.data.data.mainBusinesses;
                        _this.centerBanner=response.data.data.centerBanner;
                        _this.baseInfo=response.data.data.baseInfo;
                    }
					_this.$store.state.loading=false
                }
            ).catch(
                (error)=>{
                    _this.$message.error(error+'获取当前数据失败，请重新进入页面');
					_this.$store.state.loading=false
                }
            )
        },
        goToDetail(item){
            let params={}
            this.$router.push({name: item.url,params:params});
        }
    }
    
}
</script>
<style lang="css" scoped>
.about_main{
	padding:0 6vw;
}
.about_ssmr{
	font-size: 3.1vw;
	font-weight: bold;
	color: #ec6602;
	line-height: 5vw;
	padding-left:2vw;
	position: relative;
	margin: 2vw 0 5vw;
}
.about_ssmr::after{
	content: '';
	position: absolute;
	left:0;
	top:1vw;
	bottom: 1vw;
	border-left:2px solid #ec6602;
}
.about_text{
	font-size: 2.8vw;
	color: #808080;
	line-height: 5vw;
	padding-bottom: 9vw;
}
.about_text2{
	font-size: 2.8vw;
	color: #808080;
	line-height: 5vw;
	padding-bottom: 2vw;
}
.about_title{
	padding-top: 10vw;
	padding-bottom: 2vw;
}
.about_title .name{
	font-size: 5vw;
	font-weight: bold;
	color: #ec6602;
	line-height: 6vw;
}
.about_title .text{
	font-size: 3.2vw;
	color: #bfbfbf;
	line-height: 4vw;
}
.business{
	padding-bottom: 7vw;
}
.business img{
    display: block;
	margin: 1vw 0 4vw;
}
.line{
	border-top:1px solid #ddd;
}
.swiper{
	--swiper-pagination-color: #009999;
	--swiper-pagination-bullet-size:2.5vw;
	--swiper-pagination-bullet-horizontal-gap:1.3vw;
}
.swiper-wrapper{
	padding-bottom: 9vw;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
	bottom: 0;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  left: 0;
  width: 100%;
}
.swiper-bg{
    background: #fff;
}
.swiper-last{
    padding-bottom: 30px;
}
.honor{
	padding:4vw 0 10vw;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.honor .ph{
	width: 33vw;
	height: auto;
	flex-shrink: 0;
	margin-right: 3vw;
	margin-top: 1vw;
}
.honor .text{
	width: 100%;
	font-size: 3vw;
	color: #808080;
	line-height: 5.2vw;
}

.contact{
	background: #ededed;
	padding:7vw 6vw 8vw;
}
.contact .contact_btn{
	display: block;
	font-size: 3.6vw;
	font-weight: bold;
	color: #fff;
	line-height: 8vw;
	text-align: center;
	width: 50vw;
	height: 8vw;
	background: #ec6602;
	border-radius: 25vw;
	margin: 6vw auto 0;
}
.contact_title .name{
	font-size: 5.3vw;
	font-weight: bold;
	color: #ec6602;
	line-height: 6vw;
}
.contact_title .text{
	font-size: 3.4vw;
	color: #ec6602;
	line-height: 4vw;
}
.contact_gs{
	font-size: 3.4vw;
	font-weight: bold;
	color: #ec6602;
	line-height: 4vw;
	padding:5vw 0 4vw;
}
.contact_info{
	display: flex;
	align-items: center;
	padding:1.2vw 0;
}
.contact_info .ico{
	flex-shrink: 0;
	width: 3vw;
	margin-right: 1.5vw;
}
.contact_info .text{
	font-size: 2.8vw;
	color: #808080;
	line-height: 2.6vw;
}
</style>