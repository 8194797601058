<template>
    <div>
      <el-button type="primary" :disabled="state" @click="updateCurrentUrl" round> 更新url 
        <span v-if="state">({{stateNum}})</span> 
      </el-button>
    </div>
  </template>
  
  <script>
    export default {
      data(){
        return {
          checked: false,
          state: false,
          stateNum: 3
        }
      },
      props:['openId','url'],
      methods:{
        updateCurrentUrl () {
          this.state = true
          
          // 执行网络请求
          this.$http(
            {
              method: 'post',
              url: `/api/setUrl`,
              data: {
                "url": this.url,
                "openId": this.openId
              }
            }
          ).then(
            (response)=>{
              if(response.data.code!=200){
                //失败
                this.$message.error(response.data.msg);
              }else{
                //成功
                this.$message.success(response.data.msg);
                this.initData();
              }
            }
          ).catch(
            (error)=>{
              this.$message.error('更新失败，请重试');
            }
          )
          
          this.stateNum = 5
          let timer = setInterval(() => {
            this.stateNum--
            if (this.stateNum <= 0) {
              clearInterval(timer)
              this.state = false
            }
          }, 1000)
        },
        initData(){
          this.$emit("initData");
        }
      },
      mounted(){
        
      },
      beforeDestroy(){

      }
    }
  </script>
  
  <style lang="css" scoped>
  .el-button--primary{
    width: 100%;
    border: 0;
    background: orange;
    margin-top: 1%;
    margin-bottom: 10%;
  }
  
  </style>