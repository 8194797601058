<template>
  <div>
    <el-button  class="btn_yellow"  type="primary" :disabled="state" @click="bindingMail" round> 立 即 绑 定 
    <span v-if="state">({{stateNum}})</span> 
  </el-button>
  </div>
</template>

<script>
  import {mixin} from '@/mixin';
  import Cookies from 'js-cookie';
  export default {
    data(){
      return {
        checked: false,
        state: false,
        stateNum: 10,
      }
    },
    mixins:[mixin],
    props:['toSend','vcode','openId','to'],
    methods:{
      bindingMail () {
        // alert("注册按钮控件to="+this.to)
        //检查是否同意协议
        if(!this.checked){
          this.$message.error('请先勾选同意隐私条款');
          return;
        }
        if (!this.state) {
          // 执行网络请求
          let _this=this;
          this.$http(
            {
              method: 'post',
              url: `/api/binding/vcode/check`,
              data: {
                "sendTo": this.toSend,
                "vcode": this.vcode,
                "openId": this.openId
              }
            }
          ).then(
            (response)=>{
              if(response.data.code==8888){
                Cookies.set('Authorization', response.data.tocken,{ secure: true });
                // localStorage.setItem('Authorization',response.data.tocken);
                if(!!_this.to){
                  let params={
                    openId: _this.openId
                  }
                  _this.$router.push({name: _this.to,params:params});
                  return;
                }
                let params={
                  openId: _this.openId,
                }
                this.$router.push({name: '/manage', params:params});
                return;
              }
              if(response.data.code!=200){
                //失败
                this.$message.error(response.data.msg);
              }else{
                Cookies.set('Authorization', response.data.tocken,{ secure: true });
                // localStorage.setItem('Authorization',response.data.tocken);
                //成功
                this.$message.success(response.data.msg);
                if(!!_this.to){
                  let params={
                    openId: _this.openId
                  }
                  _this.$router.push({name: _this.to,params:params});
                  return;
                }
                if(!!response.data && !!response.data.url){
                  window.location.replace(response.data.url);
                }else{
                  this.$message.error("找不到跳转的链接");
                }
              }
            }
          ).catch(
            (error)=>{
              this.$message.error('发生异常导致绑定失败，请重新绑定');
            }
          )
          this.state = true
          this.stateNum = 10
          let timer = setInterval(() => {
            this.stateNum--
            if (this.stateNum <= 0) {
              clearInterval(timer)
              this.state = false
            }
          }, 1000)
        }
      }
    },
    mounted(){
      if (!this.openId || this.openId.length<3) {
        this.$message.error('无法获取微信标识，请重新打开页面');
      }
      this.$bus.$on("ifPrivacyChecked",(value)=>{
        this.checked=value
      })
    },
    beforeDestroy(){
      this.$bus.$off("ifPrivacyChecked");
    }
  }
</script>

<style lang="css" scoped>
.btn_yellow{
	width: 100%;
	box-sizing: border-box;
	border:1px solid #333;
	background: #ffae00;
	height: 10vw;
	font-size: 4.2vw;
	border-radius: 5vw;
	outline: none;
	color: #000;
	text-align: center;
	cursor: pointer;
	font-weight: bold;
}

</style>