<template>
  <div id="app" :class="this.$store.state.appBg" v-loading="this.$store.state.loading" 
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <headCrumbs></headCrumbs>
    <router-view></router-view>
  </div>
</template>

<script>
import headCrumbs from '@/components/commons/HeadCrumbs.vue'

export default {
  name: 'App',
  components: {
    headCrumbs,
  },
  data(){
    return {
      src: require('@/assets/banner.jpg'),
    }
  }
}
</script>

<style>
  *{margin:0;padding:0;}
  html,body{-webkit-text-size-adjust:none;}
  body{font-size:14px;color:#333;background:#fff; font-family:PingFangSC-Semibold, PingFang SC,"微软雅黑";}
  ol li,ul li{list-style-type:none; vertical-align:middle;}
  img{border:none;vertical-align:middle;}
  input{vertical-align:middle;}
  font{font-size:12px;}
  table{border-collapse: collapse;border-spacing: 0;}
  h1,h2,h3,h4,h5,h6,strong{font-weight:normal;}
  #app{
    height: 100vh;
  }
  a{text-decoration:none;}

  .login_bg{
	  background: #de6a0a;
  }
  .strategy_list_bg{
    background: #efefef;
    padding: 0;
  }
  .bgfff{
    background: #fff;
  }
  

</style>
