<template>
    <div>
    </div>
  </template>
  
  <script>
    import {mixin} from '@/mixin.js';
    import Cookies from 'js-cookie';

    export default {
      components:{
      },
      mixins:[mixin],
      data() {
        return {
            resourceUrlPre: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe33298f1908262c0&redirect_uri="+
            "https%3A%2F%2Fcommunication-in-shenzhen.siemens-healthineers.cn%2Fauth%3Fnum%3D2",
            resourceUrlSub: "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect",
            codeID: '',
            tag:0,
            to: '',
        }
      },
      methods:{
        //页面分发
        async initData(){
            const _this=this;
            // alert("authIndex page")
            let param={"codeID": _this.codeID}
            await this.$http.post("/api/auth", param).then(
                (response)=>{
                    // _this.$message({ message: response.data.msg, type: 'success' });
                    if(response.data.code==403 ||response.data.code==500){//重新获取数据
                        // alert("重新获取数据to="+this.to)
                        if(!!_this.tag){
                          _this.$message.error('获取微信信息错误，请重新进入');
                          throw false;
                        }
                        window.location.replace(_this.resourceUrlPre+encodeURIComponent(_this.to)+_this.resourceUrlSub);
                        return
                    }
                    if(response.data.code==501){//跳转到登录页
                      // alert("跳转到登录页to="+this.to)
                      let params={ "to":_this.to,"openId":response.data.openId}
                        _this.$router.push({name: '/login',params:params});
                        return
                    }
                    if(response.data.code==200 && !!response.data.tocken){//非管理员登录成功，绑定tocken并跳转到指定页面
                        // alert("绑定tocken并跳转到指定页面to="+this.to+"=="+response.data.openId)
                        Cookies.set('Authorization', response.data.tocken,{ secure: true });
                        // localStorage.setItem('Authorization',response.data.tocken);
                        if(!!_this.to){
                          if(_this.to=="/manage"){//敏感页面不可访问
                            // window.location.replace(response.data.url);
                            _this.$message.error("非管理员无法访问该页面，请选择其他页面");
                            return
                          }
                          let params={"openId":response.data.openId}
                          _this.$router.push({name: _this.to,params:params});
                          return;
                        }
                        if(!!response.data.url){
                          window.location.replace(response.data.url);
                          return;
                        }
                        return
                    }
                    if(response.data.code==404 && !!response.data.tocken){//非管理员且登录成功，未获取到指定url
                      _this.$message.error('获取页面地址失败，请重新进入页面');
                      return
                    }
                    if(response.data.code==201 && !!response.data.tocken){//绑定tocken并跳转到管理员页面
                        // alert("绑定tocken并跳转到管理员页面to="+this.to)
                        Cookies.set('Authorization', response.data.tocken,{ secure: true });
                        // localStorage.setItem('Authorization',response.data.tocken);
                        let params={"openId":response.data.openId}
                        if(!!_this.to){
                          _this.$router.push({name: _this.to,params:params});
                          return;
                        }
                        _this.$router.push({name: response.data.url,params:params});
                        return
                    }
                }
            ).catch(
                (error)=>{
                    _this.$message.error(error+'获取当前url失败，请重新进入页面');
                    window.location.replace(_this.resourceUrl);
                    return
                }
            )
        }
      },
      async created(){
        if(!this.$route.query){
          this.$message.error('获取微信信息错误，请重新进入');
          return;
        }
        this.codeID=this.$route.query.code;
        this.to=this.$route.query.to
        this.tag=this.$route.query.num
        // alert("to="+this.to)
        await this.initData();
      },
      mounted(){

      }
      
    }
  </script>
  