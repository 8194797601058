<template>
    <div>
      <el-button type="primary" :disabled="state" @click="changePage" round> 
          访问当前地址 <span v-if="state">({{stateNum}})</span> 
      </el-button>
    </div>
  </template>
  
  <script>
    import {mixin} from '@/mixin.js';
    export default {
      data(){
        return {
          checked: false,
          state: false,
          stateNum: 3,
          openId: ''
        }
      },
      mixins:[mixin],
      props:['url'],
      methods:{
        changePage(){
          this.state = true
          window.location.href=this.url;
          this.stateNum = 5
          let timer = setInterval(() => {
            this.stateNum--
            if (this.stateNum <= 0) {
              clearInterval(timer)
              this.state = false
            }
          }, 1000)
        }
      }
    }
  </script>
  
  <style lang="css" scoped>
  .el-button--primary{
    width: 100%;
    border: 0;
    background: orange;
    margin-top: 1%;
    margin-bottom: 10%;
  }
  
  </style>