<template>
	<div>
    <div v-if="this.$store.state.ifNeedHeader">
        <div v-if="this.$store.state.headerContent=='about'" class="club_header">
            <img class="club_logo" src="@/assets/logo.png" />
        </div>
        <div v-if="this.$store.state.headerContent=='club' || this.$store.state.headerContent=='map'" class="club_header">
            <img class="club_logo" src="@/assets/logo.png" />
            <a href="about" hover-class="none">关于我们</a>
        </div>
    </div>
    <div :class="this.$store.state.headerBanner" v-if="!!this.$store.state.imgSrc">
        <img :src="this.$store.state.imgSrc" alt="">
    </div>
  </div>
</template>
<script>
export default {
	data(){
        return {
            list:{data:{title:"请刷新页面"}},
			loading:false,
        }
    },
    created(){
		
    },
    methods:{
        
    }
    
}
</script>
<style lang="css" scoped>

  .strategy_login_banner{
  }
  .strategy_login_banner img{
    width: 100%;
  }
  .login_banner{
    padding-top: 6vw;
  }
  .login_banner img{
    width: 100%;
  }
  .club_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:4vw 4vw;
  }
  .club_header a{
    color: #ec6602;
    font-size: 3vw;
  }
  .club_banner img{
    width: 100%;
    display: block;
  }
  .club_logo{
	flex-shrink: 0;
	width: 30%;
  }
</style>