<template>
    <div>
      <span class="characterExplain">当前url地址:</span>
      <el-input v-model="currentUrl" readonly="readonly" placeholder="当前url地址"></el-input>
      <span class="characterExplain">替换地址:</span>
      <el-input v-model="newUrl" placeholder="请输入新的访问地址"></el-input>
      <UpdateUrlButton :url="newUrl" :openId="openId" @initData="initData"></UpdateUrlButton>
      <IntoUrlButton :url="currentUrl"></IntoUrlButton>
    </div>
  </template>
  
  <script>
    import UpdateUrlButton from '@/components/manager/UpdateUrlButton.vue'
    import IntoUrlButton from '@/components/manager/IntoUrlButton.vue'
    import {mixin} from '@/mixin.js';

    export default {
      components:{
        UpdateUrlButton,IntoUrlButton
      },
      mixins:[mixin],
      data() {
        return {
          currentUrl: '',
          newUrl: '',
          openId: ''
        }
      },
      methods:{
        async initData(){
          let _this=this
          // 执行网络请求
          await this.$http(
            {
              method: 'post',
              url: `/api/getUrl`,
              data: {
                "openId": this.openId
              }
            }
          ).then(
            (response)=>{
              if(response.data.code==501){//无openid，跳转到初始登录页
                window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe33298f1908262c0&redirect_uri=https%3A%2F%2Fcommunication-in-shenzhen.siemens-healthineers.cn%2Fauth%3Fnum%3D2%26to%3D"+
                  encodeURIComponent("/manage")+"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect");
                return;
              }
              if(response.data.code==200){
                //成功
                _this.currentUrl=response.data.data;
                return;
              }
              if(response.data.code==404){//非管理员
                //失败
                _this.$message.error("非管理员："+response.data.msg);
                return;
              }
              //其他失败
              _this.$message.error("未知错误："+response.data.msg);
            }
          ).catch(
            (error)=>{
              _this.$message.error('获取当前url失败，请重新进入页面');
            }
          )
        }
      },
      async created(){
        debugger
        if(!!this.$route.params){
          this.openId=this.$route.params.openId
        }
        if(!this.openId){
          window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe33298f1908262c0&redirect_uri=https%3A%2F%2Fcommunication-in-shenzhen-test.siemens-healthineers.cn%2Fauth%3Fnum%3D2%26to%3D"+
            encodeURIComponent("/manage")+"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect");
          return;
        }
      },
      async mounted(){
        await this.initData();
      }
      
    }
  </script>
  
  <style lang="css" scoped>
  .el-input /deep/ .el-input__inner{
    color: white;
    background: #FF790A;
    border-radius: 18px;
    border: 1px solid #FF790A;
    outline: none;
    width: 100%;
    height: 40px;
    margin-bottom: 15%;
  }
  .characterExplain{
    color: white;
    font-size:medium;
  }
  </style>