<template>
  <div>
    <div class="login_checkbox">
      <label class="checkbox_label">
        <el-checkbox class="el-checkbox-class" v-model="checked"></el-checkbox>
        <span>
          本人理解并同意西门子医疗根据<a @click="ifShowPrivacyDetail=true">《用户隐私政策》</a>对本人个人信息的处理行为。
        </span>
      </label>
    </div>
    <div class="login_checkbox" style="padding-top: 4vw;">
      <label class="checkbox_label">
        <el-checkbox class="el-checkbox-class" v-model="checked2"></el-checkbox>
        <span>
          本人理解并同意，为了<a @click="ifShowPrivacyDetail=true">《用户隐私政策》</a>所列目的，我的个人信息（在与各目的相关的范围内）可能被转移给西门子医疗的关联公司、其他第三方（包括公司的代理商、服务提供商、客户、供应商、审计人员等）、政府部门、司法部门等接收方。
        </span>
      </label>
    </div>
    <el-dialog 
      :visible.sync="ifShowPrivacyDetail"
      :fullscreen="true"
      :lock-scroll="true"
      width="100%">
      <div class="clause">
        <h1>用户隐私政策</h1>
        <p>
          尊敬的【iSSMR公众号】的用户（以下简称“您”），我们，注册地址位于【深圳市南山区高新区高新中二道西门子磁共振园】的【西门子医疗-西门子（深圳）磁共振有限公司】（以下简称“西门子医疗”）希望通过本隐私政策帮助您了解您在使用西门子医疗提供的【iSSMR公众号】的过程中，西门子医疗如何处理您的个人信息以及您对于这些个人信息所享有的权利（“本隐私政策”）。
        </p>
        <p>
          本隐私政策将帮助您了解以下内容：<br>
          1.西门子医疗为何处理您的个人信息以及处理您个人信息的类别<br>
          2.个人信息的委托处理、共享和披露<br>
          3.个人信息的保护<br>
          4.个人信息的保留期限<br>
          5.未成年人的个人信息<br>
          6.您的权利<br>
          7.西门子医疗个人信息保护机构<br>
          8.内容变更
        </p>
        <p>
          <strong>1.西门子医疗为何处理您的个人信息以及处理您个人信息的类别</strong>
        </p>
        <p>
          1.1.个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
          <br>个人信息的处理包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。
        </p>
        <p>
          1.2.我们会出于以下目的收集和使用您的以下个人信息:<br>		
          <table class="policy_table">
            <tr>
              <th>序号</th>
              <th>目的</th>
              <th>个人信息类别</th>
            </tr>
            <tr>
              <td>1</td>
              <td>帮助员工注册为iSSMR平台的绑定用户</td>
              <td>员工邮箱和验证码信息</td>
            </tr>
            <tr>
              <td>2</td>
              <td>确认您的绑定用户身份</td>
              <td>微信 Open ID</td>
            </tr>
            <tr>
              <td>3</td>
              <td>识别您的关注用户身份</td>
              <td>您的微信头像和昵称</td>
            </tr>
          </table>
        </p>
        <p>
          <strong>2.个人信息的委托处理、共享和披露</strong>
        </p>
        <p>
          2.1.个人信息的委托处理<br>
          为实现上述第1条所述之目的，西门子医疗有可能委托有关第三方（“委托处理方”）处理您的个人信息，比如系统提供商、数据处理服务提供商、云服务提供商。届时西门子医疗将要求该等第三方按照适用的法律法规的要求，采取相应的安全措施保护该等个人信息，并且该等保护应与西门子医疗采取的个人信息保护措施在程度上相当。
        </p>
        <p>
          2.2.个人信息的共享<br>
          为实现上述第1条所述之目的，西门子医疗有可能将您的个人信息提供给以下第三方：<br>
          <table class="policy_table">
            <tr>
              <th>序号</th>
              <th>被提供方</th>
              <th>处理目的和方式</th>
              <th>所涉及的个人信息的种类</th>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </p>
        <p>
          此外，在适用法律法规允许或者要求的情况下，西门子医疗可能会将您的个人信息共享给有关第三方，比如：<br>
          ●西门子医疗全部或部分的业务的潜在资产收购方、或在西门子医疗并购、收购或股票公开发行中的收购方或者股权认购方以及其顾问；<br>
          ●在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续依据本隐私政策所披露的内容处理您的个人信息。如新的持有您个人信息的公司、组织需要将您的个人信息用于本隐私政策未载明的目的，新的公司或组织将会依照可适用的法律法规的规定取得您的同意。<br>
          ●与遵守法律义务或与提出、行使或抗辩法律诉求相关的第三方（例如为诉讼或仲裁程序，向执法机构、监管机构，向律师和顾问转让和/或共享）。
        </p>
        <p>
          2.3.个人信息的披露<br>
          西门子医疗可能会在如下的情形披露您的个人信息，比如：<br>
          ●中国法律或者任何适用于西门子医疗的其他国家的法律要求进行披露；<br>
          ●在中国法律或者任何适用于西门子医疗的其他国家的法律授权或允许的范围内，为保护西门子医疗的合法权利或者一个第三方的重大利益而进行披露；或<br>
          ●根据司法程序或政府主管部门的要求。
        </p>
        <p>
          2.4.个人信息存储<br>
          西门子医疗会在中国境内以及在为实现上述第1条所述的相关目的而需使用您个人信息的区域使用、储存以及远程访问您的个人信息。由于西门子医疗是一家跨国企业，可能与来自不同国家和地区的公司及其关联方协作，以及会与西门子医疗各种国内外的客户、供应商、经销商、采购、代理、监管机构等进行商业合作。就此，在适用法律允许的范围内，我们将允许以下位于中国境外的关联公司和相关的服务提供商（如系统提供商）访问、使用或存储您的个人信息。
          <table class="policy_table">
            <tr>
              <th>序号</th>
              <th>境外接收方（名称和联系方式）</th>
              <th>处理目的和方式</th>
              <th>所涉及的个人信息的种类</th>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </p>
        <p>
          此外，在遵守适用法律法规的前提下，我们向您收集的或您向我们提供的关于您的个人信息（包括姓名、常用名、性别、职位、部门、员工号、工作邮箱地址、公司地址、手机号、传真号、个人电子邮箱，以我们实际收集到的个人信息的为限）将传输至我们位于德国的西门子医疗总部<b>Siemens Healthineers AG</b>，其联系方式及行权渠道为：<b>dataprivacy.func@siemens-healthineers.com</b>，处理目的为开展西门子医疗集团内统一的人力资源管理、便利西门子医疗集团内部跨国沟通与交流以及向西门子医疗提供信息技术服务支持，处理方式包括收集、使用、存储、保存和委托处理您的个人信息。
        </p>
        <p>
          <b>为保护跨境传输的个人信息，我们将与境外接收方签订《个人信息出境标准合同》并约定您为第三方受益人，如您未在30日内明确拒绝，您将可以根据该合同享有第三方受益人的权利。</b>如您希望了解关于个人信息跨境传输的更多信息（如请求我们提供与境外接收方签订的《个人信息出境标准合同》副本或者请求删除您的个人信息），可以随时联系我们。
        </p>
        <p>
          <strong>3.个人信息的保护</strong>
        </p>
        <p>
          西门子医疗将会采取适当的技术措施和管理措施来保护您的个人信息不受未经授权的访问、修改、分发、披露或删除。<b>请您知悉并理解，互联网并非绝对安全的环境。如您发现自己的个人信息发生泄漏的，请您立即通过本隐私政策下方列出的联系方式联系我们，以便我们采取相应措施。</b>
        </p>
        <p>
          <strong>4.个人信息的保留期限 </strong>
        </p>
        <p>
          除非在收集您个人信息时另有约定，若保留信息已不再为个人信息收集或另行处理时的目的或为遵守法律义务所需要（例如税法或商业法下的法定保留义务），公司将删除您的个人信息。<br>
          如您想主动要求相应删除，请联系<b>【shs_ssmr_official_accounts.team@siemens-healthineers.com】</b>。如届时法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，西门子医疗应当停止除存储和采取必要的安全保护措施之外的处理。
        </p>
        <p>
          <strong>5.未成年人的个人信息</strong>
          我们的服务主要面向年满18周岁的成年人提供，我们一般不直接从未成年人收集其个人信息。<b>如果您认为我们未经过您同意处理了您的未成年子女的个人信息，或者对我们处理您的未成年子女的个人信息有任何问题或者疑虑，请通过本隐私政策底部所示的联系方式与我们联系。</b>
        </p>
        <p>
          <strong>6.您的权利</strong>
          受制于相关法律要求，您可能有权：<br>
          ●与西门子医疗确认其是否正在处理与您有关的个人信息，并且若在处理的话，您可以访问该个人信息; <br>
          ●借助西门子医疗更正有关您的不准确的个人信息；<br>
          ●借助西门子医疗删除您的个人信息；<br>
          ●撤销您的同意，但该等撤销不影响基于您的同意已经做出的合法处理行为。<br>
          ●借助西门子医疗限制对您的个人信息的处理； <br>
          ●获得您的个人信息的副本；<br>
          ●以您的特定情况为理由反对进一步处理与您有关的个人信息。
        </p>
        <p>
        <b>请您注意，在中国法律允许的情况下，如果西门子医疗无法或在实际操作上难以按照您的上述要求行事，西门子医疗有权拒绝您的全部或部分请求，并仅需于合理时间内向您说明拒绝理由。特别是，西门子医疗有权在中国法律允许的情况下在下列任一情形下拒绝您的请求：</b><br>
          ●所请求信息是与西门子医疗履行法律法规义务直接相关的；<br>
          ●所请求信息是与国家安全、国防安全直接相关的；<br>
          ●所请求信息是与公共安全、公共卫生、重大公共利益直接相关的；<br>
          ●所请求信息是与犯罪侦查、起诉、审判和执行判决等直接相关的；<br>
          ●西门子医疗有合理证据表明您存在主观恶意或滥用权利的；<br>
          ●响应您的请求将导致您或其他个人、实体或组织的合法权益受到损害的；<br>
          ●出于维护您或其他自然人的生命、财产等重大合法权益但又很难得到您的同意的；和<br>
          ●所请求信息涉及商业秘密。
        </p>
        <p>
          <strong>7.西门子医疗个人信息保护机构</strong>
          西门子医疗个人信息保护机构为任何个人信息保护有关的问题、意见、疑虑或投诉提供支持，也可在您希望行使任何与个人信息保护相关的权利时提供支持。您可以通过联系以下邮箱获取关于西门子医疗个人信息保护机构、西门子医疗个人信息保护体系的信息或寻求支持：dataprivacy.func@siemens-healthineers.com.
          西门子医疗个人信息保护机构会尽商业合理可行的努力回应、解决收到的问题和投诉。除联系西门子医疗个人信息保护机构，您也可能有权向相应个人信息保护主管机关反映您的问题或投诉。 
        </p>
        <p>
          <strong>8.内容变更</strong>
          西门子医疗有权修订本告知函的内容，当（发生）更新时，我们会在西门子医疗的网站以及我们认为合适的其他地方公布变更内容，并以口头、书面、电话、短信、电子邮件、传真、电子文档等方式向您提供修订概要。以确保您始终了解我们收集了哪些信息、如何使用以及我们在何种情况下（如果有）会分享或公开这些信息。
          您可以通过本隐私政策的“最后更新时间”了解到本隐私政策的更新时间。
        </p>
        <el-button class="btn_close" @click="ifShowPrivacyDetail = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        ifShowPrivacyDetail: false,
        checked:false,
        checked2:false,
      };
    },
    watch:{
      "checked":{
        handler(newVal){
          this.$bus.$emit("ifPrivacyChecked",newVal && this.checked2)
        }
      },
      "checked2":{
        handler(newVal){
          this.$bus.$emit("ifPrivacyChecked",newVal && this.checked)
        }
      }
    }
  }
</script>

<style lang="css" scoped>
.login_checkbox {
	display: flex;
	font-size: 3vw;
	font-weight: normal;
	line-height: 4.2vw;
}
.login_checkbox a{
	color:#ffae00;
	margin-left: 1vw;
	text-decoration:underline;
}
.login_checkbox /deep/ .el-checkbox__inner{
	margin-right: 1vw;
}
.el-checkbox-class{
  zoom: 75%;
  flex-shrink: 0;
	margin-right: 1vw;
  margin-top: 0.8vw;
}
.login_checkbox label{
	display: flex;
	/* align-items: center; */
}
.btn_close{
	display: block;
	width: 20vw;
	box-sizing: border-box;
	border:1px solid #333;
	background: #ffae00;
	height: 8vw;
	font-size: 3vw;
	border-radius: 1vw;
	outline: none;
	color: #000;
	text-align: center;
	cursor: pointer;
	margin: 3vw auto;
}

.el-dialog__body /deep/ .clause {
	position: absolute;
	left: 4vw;
	right: 4vw;
	top:8vh;
	bottom: 8vh;
	border-radius: 1vw;
	overflow-y: auto;
	padding:3vw 4vw;
	font-size: 2.2vw;
	line-height: 4vw;
	color:#333;
	font-weight: normal;
	z-index: 999;
	box-shadow: 0 1vw 5vw rgba(0, 0, 0, 0.2);
}
strong{
	display: block;
	font-weight: bold;
	font-size: 2.2vw;
	line-height: 4.5vw;
	color:#333;
}
h1{
	font-size: 3.4vw;
	text-align: center;
	line-height: 6vw;
}
h3{
	font-size: 2.5vw;
	line-height: 5vw;
	padding-top: 2vw;
}
p{
	font-size: 2.2vw;
	line-height: 4vw;
	color:#333;
	font-weight: normal;
}
.checkbox_label{
	display: flex;
	
}
.checkbox_input{
	flex-shrink: 0;
	margin-right: 1vw;
}
.clause_bg{
	display: none;
	position: fixed;
	left:0;
	top:0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 2;
}
.clause{
	position: absolute;
	left: 4vw;
	right: 4vw;
	top:8vh;
	bottom: 8vh;
	background: #fff;
	border-radius: 1vw;
	overflow-y: auto;
	padding:3vw 4vw;
	font-size: 2.2vw;
	line-height: 4vw;
	color:#333;
	font-weight: normal;
	z-index: 999;
	box-shadow: 0 1vw 5vw rgba(0, 0, 0, 0.2);
}
.clause strong{
	display: block;
	font-weight: bold;
	font-size: 2.2vw;
	line-height: 4.5vw;
	color:#333;
}
.clause h1{
	font-size: 3.4vw;
	text-align: center;
	line-height: 6vw;
}
.clause h3{
	font-size: 2.5vw;
	line-height: 5vw;
	padding-top: 2vw;
}
.clause p{
	font-size: 2.2vw;
	line-height: 4vw;
	color:#333;
	font-weight: normal;
	margin-top: 2vw;
}
.policy_table{
	width: 100%;
	margin-top: 1vw;
	text-align: center;
}
.policy_table th,.policy_table td{
	border:1px solid #ddd;
}
::v-deep  .el-dialog{
  background-color: rgba(220,38,38,0.2);
  }
</style>