<template>
    <div v-loading="loading" 
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="list_banner">
            <a href="/poster"><img src="@/assets/ph_1.jpg" width="100%" alt=""></a>
            <!-- <a href="https://forms.office.com/pages/responsepage.aspx?id=3Rq_XSogjUuBW78PsCTgMyHDEn_WepJHr8eghRLpSbtURURCN0dSWUZRRjA2QTdGMDk4UDZYWTNKNyQlQCN0PWcu&origin=QRCode&qrcodeorigin=presentation"><img src="@/assets/ph_1.jpg" width="100%" alt=""></a> -->
        </div>
        <el-form v-model="list" ref="form" :inline="true" class="list">
            <el-row :gutter="10" v-for="(item,index) in list" :key="index" class="list-li">
                <img :src="item.itemImgSrc" width="100%" alt="" @click="goToDetail(item)">
                <p  @click="goToDetail(item)">{{ item.name }}</p>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return {
            list:[{name:"",itemImgSrc:"",url:""},],
            loading:false,
        }
    },
    async mounted(){
        this.$store.state.imgSrc= require('@/assets/img_1.jpg');
        this.$store.state.appBg= "strategy_list_bg";
        this.$store.state.headerBanner= "strategy_login_banner";
        this.$store.state.ifNeedHeader= false;
        this.loading=true
        await this.initData()
    },
    methods:{
        async initData(){
            let _this=this;
            await this.$http.get("/api/getStrategyList").then(
                (response)=>{
                    if(response.data.code==200){
                        _this.list=response.data.data;
                    }
                    this.list.forEach(item=>{item.itemImgSrc=require("@/assets/"+item.itemImgSrc)})
                    this.loading=false
                }
            ).catch(
                (error)=>{
                    _this.$message.error(error+'获取当前数据失败，请重新进入页面');
                    this.list.forEach(item=>{item.itemImgSrc=require("@/assets/"+item.itemImgSrc)})
                    this.loading=false
                }
            )
        },
        goToDetail(item){
            let params={}
            this.$router.push({name: item.url,params:params});
        }
    }
    
}
</script>
<style lang="css" scoped>
    .list_img{
        width: 100%;
        position: relative;
        z-index: 1;
    }
    .list_banner{
        position: relative;
        margin-top: -12vw;
        padding:0 4vw;
        z-index: 3;
    }
    .list{
        padding:0 4vw !important;
    }
    .list{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 4vw !important;
    }
    .list-li{
        margin: 0 !important;
    }
    .list .list-li{
        margin-top: 4vw !important;
        width: 48%;
        background: #fff;
    }
    .list .list-li p{
        color:#333;
        font-size: 3.4vw;
        line-height: 8vw;
        padding:3vw 3vw 2vw;
        position: relative;
    }
    .list .list-li p::after{
        content: '';
        position: absolute;
        left:3vw;
        top:2.8vw;
        width: 8vw;
        border-top: 2px solid #de6a0a;
    }
</style>