<template>
  <div>
    <el-button style="align-items: center" class="btn_green" type="primary" :disabled="state" @click="sendVcode" round> 
      <img src="@/assets/ico_3.png" alt="">
      <span>点击获取邮箱验证码</span>
      <span v-if="state">({{stateNum}})</span> 
    </el-button>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        //状态值，false时可用发送，用于倒计时控制发送
        state: false,
        stateNum: 60,
      }
    },
    props:['toSend'],
    methods:{
      sendVcode () {
        //是否倒计时结束
        if (!this.state) {
          // 执行网络请求
          this.$http(
            {
              method: 'post',
              url: `/api/binding/send`,
              data: {
                sendTo: this.toSend,
                subject: '公众号深圳视界传媒绑定邮箱认证验证码'
              }
            }
          ).then(
            (response)=>{
              if(response.data.code!=200){
                //失败
                this.$message.error(response.data.msg);
              }else{
                //成功
                this.$message.success(response.data.msg);
              }
            }
          ).catch(
            (error)=>{
            //   if(!!error.response && !!error.response.data && error.response.data.code=='444'){
            //     this.$message.error('授权信息失效');
            //     window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe33298f1908262c0&redirect_uri=https%3A%2F%2Fcommunication-in-shenzhen.siemens-healthineers.cn%2Fauth%3Fnum%3D2%26to%3D"+
            // encodeURIComponent("/manage")+"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect");
            //     return
            //   }
              this.$message.error('未发送成功，请重新发送');
            }
          )
          this.state = true
          this.stateNum = 60
          let timer = setInterval(() => {
            this.stateNum--
            if (this.stateNum <= 0) {
              clearInterval(timer)
              this.state = false
            }
          }, 1000)
        }
      }
    }
  }
</script>

<style lang="css" scoped>
.btn_green{
	width: 100%;
	box-sizing: border-box;
	border:1px solid #333;
	background: #009999;
	height: 10vw;
	font-size: 3.6vw;
	border-radius: 5vw;
	outline: none;
	color: #ffae00;
	cursor: pointer;
	display: flex;
	align-items: center !important;
	box-sizing: border-box;
	padding-left:5vw !important;
}
.btn_green img{
	flex-shrink: 0 !important;
	width: 4.5vw;
	margin-right: 4vw;
 
}
img{
  margin-bottom: 0.5vw !important;
}
.el-button{
}
.is-round{

}
.el-button--primary{
  
}
button{
  font-weight:'' !important;
  line-height: normal !important;
}
</style>