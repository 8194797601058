//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
import LoginIndex from "@/components/auth/LoginIndex.vue"
import AuthIndex from "@/components/auth/AuthIndex"
import ManageUrl from "@/components/manager/ManageUrl.vue";
import Strategy from "@/components/guide/Strategy"
import about from "@/components/guide/About"
import map from "@/components/guide/Map"
import club from "@/components/guide/Club"
import poster from "@/components/guide/Poster"
import axios from 'axios'
import Cookies from 'js-cookie'
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
    mode:'history', 
    //4.配置路由的path和组件
    routes :[
      {
        path: "/login",
        name:'/login',
        component: LoginIndex,
        meta: {
          title: 'iSSMR-注册&登录',
          keepAlive: false, // 需要被缓存
        }
      },
      {
        path: "/auth",
        name:'/auth',
        component: AuthIndex,
        meta: {
          title: 'iSSMR-西家',
          keepAlive: false, 
        }
      },
      {
        path: "/manage",
        name:'/manage',
        component: ManageUrl,
        meta: {
          title: 'iSSMR-西家配置',
          keepAlive: false, 
        }
      },
      {
        path: "/strategy",
        name:'/strategy',
        component: Strategy,
        meta: {
          title: 'iSSMR-西家指南',
          keepAlive: false, 
        }
      },
      {
        path: "/about",
        name:'/about',
        component: about,
        meta: {
          title: 'iSSMR-关于我们',
          keepAlive: false, 
        }
      },
      {
        path: "/map",
        name:'/map',
        component: map,
        meta: {
          title: 'iSSMR-办公地图指引',
          keepAlive: false, 
        }
      },
      {
        path: "/club",
        name:'/club',
        component: club,
        meta: {
          title: 'iSSMR-工会俱乐部活动',
          keepAlive: false, 
        }
      },
      {
        path: "/poster",
        name:'/poster',
        component: poster,
        meta: {
          title: 'iSSMR-活动介绍',
          keepAlive: false, 
        }
      },
    ]
})
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach(async (to, from, next) => {
  window.document.title = to.meta.title == undefined?'默认标题':to.meta.title
  // next()
  //todo 删除上一句，解开下面注释
  if (to.path === '/auth' || to.path === '/login' || to.path === '/Login' || to.path === '/about') {
      next();
  } else {
      // let token = localStorage.getItem('Authorization');
      let token = Cookies.get('Authorization');
      if (!token) {//没有授权信息
          window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe33298f1908262c0&redirect_uri=https%3A%2F%2Fcommunication-in-shenzhen.siemens-healthineers.cn%2Fauth%3Fnum%3D2%26to%3D"+
          encodeURIComponent(to.path)+"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect");
          return
          // next('/auth');
      } else {
          //验证tocken
          await axios.post("/api/validate", {"tocken":token}).then(
            (response)=>{
              // alert("index validate"+response.data.code)
              if(response.data.code==200){
                next();
              }else{
                window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe33298f1908262c0&redirect_uri=https%3A%2F%2Fcommunication-in-shenzhen.siemens-healthineers.cn%2Fauth%3Fnum%3D2%26to%3D"+
                encodeURIComponent(to.path)+"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect");
                return;
              }
            }
          )
      }
  }
});
//5.导入路由实例
export default router

