import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'; // 2.1引入结构
import { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import axios from 'axios'
import router from '@/router/index.js'
import Vuex from 'vuex'
import Cookies from 'js-cookie';

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

// import VueAwesomeSwiper from 'vue-awesome-swiper' 
// import 'swiper/dist/css/swiper.css' 

Vue.use(Vuex);
Vue.use(VueAwesomeSwiper) 
var store = new Vuex.Store({
  state:{
    loading:false,
    imgSrc: require('@/assets/banner.jpg'),
    appBg:"login_bg",
    headerBanner:"login_banner",
    headerClass:"club_header",
    ifNeedHeader:false,
  },
  mutations:{
  },
  actions:{
  }
})

Vue.use(ElementUI); // 3.安装
Vue.prototype.$http = axios

Vue.config.productionTip = false

//todo 解开下面注释
if(!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
  // alert("请使用手机微信访问"+navigator.userAgent);
  alert("请使用手机微信访问");
  window.location.href="about:blank";
  throw false;
  //判断是否是微信内置浏览器
}

//定义一个新的Message方法，多传入一个offset参数
const $message = options => {
  return Message({
    ...options,
    offset: 200
  });
};
 
//重写方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 270,
        duration:3000,
        showClose: true,
      };
    }
    options.type = type;
    return Message(options);
  };
});
//将$message挂载到this上
Vue.prototype.$message = $message;
//不加这行代码运行this.$message.closeAll时会报错
Vue.prototype.$message.closeAll = Message.closeAll;

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus=this
  }
}).$mount('#app')

// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
  config => {
    // if (localStorage.getItem('Authorization')) {
    //   config.headers.Authorization = localStorage.getItem('Authorization');
    // }
    if (Cookies.get('Authorization')) {
      config.headers.Authorization = Cookies.get('Authorization');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

